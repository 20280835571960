:root {
  --font-medium-woff2: var(--PPNeueMedium_woff2);
  --font-medium-woff: var(--PPNeueMedium_woff);
  --font-book-woff2: var(--PPNeueBook_woff2);
  --font-book-woff: var(--PPNeueBook_woff);
  --font-bold-woff2: var(--PPNeueBold_woff2);
  --font-bold-woff: var(--PPNeueBold_woff);
  --font-thin-woff2: var(--PPNeueThin_woff2);
  --font-thin-otf: var(--PPNeueThin_otf);
}

@font-face {
  font-family: 'PPNeueMontreal-Medium';
  src: url('./assets/fonts/PPNeueMontreal-Medium.woff2') format('woff2'),
       url('./assets/fonts/PPNeueMontreal-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PPNeueMontreal-Book';
  src: url('./assets/fonts/PPNeueMontreal-Book.woff2') format('woff2'),
       url('./assets/fonts/PPNeueMontreal-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PPNeueMontreal-Bold';
  src: url('./assets/fonts/PPNeueMontreal-Bold.woff2') format('woff2'),
       url('./assets/fonts/PPNeueMontreal-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PPNeueMontreal-Thin';
  src: url('./assets/fonts/PPNeueMontreal-Thin.woff2') format('woff2'),
       url('./assets/fonts/PPNeueMontreal-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'PPNeueMontreal-Thin', monospace;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Joshua Samaniego Updates */
html {
  width: 100vw;
  height: 100vh;
}
body {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
}

