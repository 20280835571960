.Nav {
    width: 92%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.TellorLogo {
    width: 140px;
}

/* Media Queries */
@media (max-width: 600px) {
    .Nav {
        flex-direction: column;
        justify-content: space-around;
        padding: 20px 0 54px;
    }
}
@media (max-width: 500px) {
    .Nav {
        justify-content: space-around;
    }
}